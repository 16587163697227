import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ProductHero from "../../components/ProductHero";
import FindLO from "../../components/FindLO";
import ProductDetails from "../../components/ProductDetails";
import Content from "../../components/Content";

import background from "../../images/enhancedLTVbg.jpg";
import icon from "./Icons_LTV_Active.png";
import styles from "./styles.module.less";

const EnhancedLTVMortgage = () => {
    const header = <Header active={["enhanced-ltv-mortgage"]} />;
    const intro = "Enhanced LTV Mortgage";
    const subhead = (
        <div>
            Helping you climb upward,
            <br /> even if you’re upside down.
        </div>
    );

    const qualifyBody = (
        <div>
            Much like a HARP refinance, qualifying for an enhanced LTV mortgage
            requires a deeper dive into your loan and payment history. It’s not
            as cut and dry as most loan programs, but we’re here to help you
            every step of the way.
            <br />
            <br />{" "}
            <a className={styles.link} href="/find-your-loan-officer">
                Talk to one of our loan officers
            </a>{" "}
            to see if an enhanced LTV mortgage is the right way to navigate your
            situation.
        </div>
    );

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>Enhanced LTV Mortgage | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="Enhanced LTV mortgages are for people who have been making mortgage payments on timebut have high loan-to-value ratios. Want to find out if you qualify? Contact us today."
                />
                <meta
                    name="keywords"
                    content="Enhanced LTV mortgage,Enhanced LTV Loan,Enhanced LTV Refinance,HARP refinance ,HARP loan,What is a HARP loan,HARP loan program"
                />
            </Helmet>
            <ProductHero
                customPhoto={background}
                icon={icon}
                header={intro}
                subhead={subhead}
            />
            <ProductDetails
                title={"What is an enhanced LTV mortgage?"}
                border={true}>
                <p>
                    Created in 2009, the Home Affordable Refinance Program
                    (HARP) afforded refinance options to homeowners whose
                    mortgage balances were higher than their property value.
                    Though the HARP loan program ended in 2018, we have a
                    replacement today called enhanced LTV mortgages—refinance
                    loans for people who have been making mortgage payments on
                    time but are burdened by high loan-to-value (LTV) ratios.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of an enhanced LTV mortgage?"}
                border={true}>
                <ul className={styles.list}>
                    <li>Reduced terms and interest rates</li>
                    <li>Lower monthly principal</li>
                </ul>
            </ProductDetails>
            <ProductDetails
                title={"What are the current enhanced LTV mortgage rates?"}
                border={true}>
                <p>
                    Interest rates for enhanced LTV refinances change all the
                    time based on market trends. While we can’t guarantee your
                    specific rate here and now,{" "}
                    <a
                        style={{ color: "#FD8A25" }}
                        href="/find-your-loan-officer">
                        talk to one of our loan officers
                    </a>{" "}
                    to lock in your exact interest rate.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How do I qualify for an enhanced LTV mortgage?"}>
                <Content content={qualifyBody} />
            </ProductDetails>
            <FindLO />
        </Layout>
    );
};

export default EnhancedLTVMortgage;
